// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/form_question.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { FormQuestionPlacement, FormQuestionScope, FormQuestionType } from "../../../common/v1/common_pb.js";
import { ProductIDAndType } from "./product_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionOption
 */
export const FormQuestionOption = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionOption",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestion
 */
export const FormQuestion = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestion",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "placement", kind: "enum", T: proto3.getEnumType(FormQuestionPlacement) },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 6, name: "label_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "label_plain_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "options", kind: "message", T: FormQuestionOption, repeated: true },
    { no: 9, name: "product_id_and_types", kind: "message", T: ProductIDAndType, repeated: true },
    { no: 10, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "is_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "scope", kind: "enum", T: proto3.getEnumType(FormQuestionScope) },
    { no: 13, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFormQuestionRequest
 */
export const CreateFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFormQuestionRequest",
  () => [
    { no: 1, name: "placement", kind: "enum", T: proto3.getEnumType(FormQuestionPlacement) },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 3, name: "label_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "label_plain_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "options", kind: "message", T: FormQuestionOption, repeated: true },
    { no: 6, name: "product_id_and_types", kind: "message", T: ProductIDAndType, repeated: true },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "scope", kind: "enum", T: proto3.getEnumType(FormQuestionScope) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFormQuestionResponse
 */
export const CreateFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFormQuestionResponse",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveFormQuestionRequest
 */
export const SaveFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveFormQuestionRequest",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveFormQuestionResponse
 */
export const SaveFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveFormQuestionResponse",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionsRequest
 */
export const ListFormQuestionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionsRequest",
  () => [
    { no: 1, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionsResponse
 */
export const ListFormQuestionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionsResponse",
  () => [
    { no: 1, name: "form_questions", kind: "message", T: FormQuestion, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFormQuestionRequest
 */
export const GetFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFormQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFormQuestionResponse
 */
export const GetFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFormQuestionResponse",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveFormQuestionRequest
 */
export const ArchiveFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveFormQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveFormQuestionResponse
 */
export const ArchiveFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveFormQuestionResponse",
  [],
);

